import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetAllIndustriesQuery,
  useCreateIndustryMutation,
  useUpdateIndustryMutation,
  useDeleteIndustryMutation,
} from "../Redux/Api/consultingServiceApi";

interface Industry {
  id?: number;
  title: string;
  description: string;
  image: string;
}

const IndustriesPage: React.FC = () => {
  const { data: industries, isLoading } = useGetAllIndustriesQuery();
  const [createIndustry] = useCreateIndustryMutation();
  const [updateIndustry] = useUpdateIndustryMutation();
  const [deleteIndustry] = useDeleteIndustryMutation();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState<Industry>({
    title: "",
    description: "",
    image: "",
  });

  const [isEditMode, setEditMode] = useState(false);

  const openDialog = (industry: Industry | null = null) => {
    if (industry) {
      setFormData(industry);
      setEditMode(true);
    } else {
      setFormData({ title: "", description: "", image: "" });
      setEditMode(false);
    }
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setFormData({ title: "", description: "", image: "" });
    setEditMode(false);
  };

  const handleSave = async () => {
    if (isEditMode && formData.id) {
      await updateIndustry({
        id: formData.id,
        data: {
          title: formData.title,
          description: formData.description,
          image: formData.image,
        },
      });
    } else {
      await createIndustry({
        title: formData.title,
        description: formData.description,
        image: formData.image,
      });
    }
    closeDialog();
  };

  const handleDelete = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this industry?")) {
      await deleteIndustry(id);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  if (isLoading) {
    return <Typography>Loading industries...</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Industries We Serve
      </Typography>
      <Button variant="contained" color="primary" onClick={() => openDialog()}>
        Add New Industry
      </Button>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {industries?.map((industry: Industry) => (
              <TableRow key={industry.id}>
                <TableCell>{industry.title}</TableCell>
                <TableCell>{industry.description}</TableCell>
                <TableCell>
                  <img
                    src={industry.image}
                    alt={industry.title}
                    style={{ width: "50px", height: "50px" }}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => openDialog(industry)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(industry.id!)}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {isEditMode ? "Edit Industry" : "Add New Industry"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            name="title"
            fullWidth
            margin="normal"
            value={formData.title}
            onChange={handleChange}
          />
          <TextField
            label="Description"
            name="description"
            fullWidth
            margin="normal"
            multiline
            value={formData.description}
            onChange={handleChange}
          />
          <TextField
            label="Image URL"
            name="image"
            fullWidth
            margin="normal"
            value={formData.image}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IndustriesPage;
