import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetAllCoursesQuery,
  useAddCoursesMutation,
  useDeleteCourseMutation,
  useUpdateCourseMutation,
} from "../Redux/Api/consultingServiceApi";

interface Course {
  id?: number;
  title: string;
  description: string;
  price: string;
  type: "live" | "recorded";
  liveLink?: string;
}

const CoursesPage: React.FC = () => {
  const { data: courses, isLoading } = useGetAllCoursesQuery();
  const [addCourses] = useAddCoursesMutation();
  const [updateCourse] = useUpdateCourseMutation();
  const [deleteCourse] = useDeleteCourseMutation();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState<Course[]>([
    {
      title: "",
      description: "",
      price: "",
      type: "recorded",
      liveLink: "",
    },
  ]);

  const openDialog = (course: Course | null = null) => {
    setFormData(
      course
        ? [{ ...course }]
        : [
            {
              title: "",
              description: "",
              price: "",
              type: "recorded",
              liveLink: "",
            },
          ]
    );
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleSave = async () => {
    if (formData.length === 1 && formData[0].id) {
      const payload = {
        id: Number(formData[0].id),
        data: {
          title: formData[0].title,
          description: formData[0].description,
          price: Number(formData[0].price),
          type: formData[0].type,
          liveLink: formData[0].type === "live" ? formData[0].liveLink : null,
        },
      };
      console.log("Payload for Update:", JSON.stringify(payload, null, 2));
      await updateCourse(payload);
    } else {
      const payload: any = {
        courses: formData.map((course) => ({
          ...course,
          price: Number(course.price),
          liveLink: course.type === "live" ? course.liveLink : null,
        })),
      };
      console.log("Payload for Add:", JSON.stringify(payload, null, 2));
      await addCourses(payload);
    }
    closeDialog();
  };

  const handleDelete = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this course?")) {
      await deleteCourse(id);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [name]: value,
    };
    setFormData(updatedFormData);
  };

  const addNewCourseField = () => {
    setFormData([
      ...formData,
      {
        title: "",
        description: "",
        price: "",
        type: "recorded",
        liveLink: "",
      },
    ]);
  };

  const removeCourseField = (index: number) => {
    const updatedFormData = formData.filter((_, i) => i !== index);
    setFormData(updatedFormData);
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Courses
      </Typography>
      <Button variant="contained" color="primary" onClick={() => openDialog()}>
        Add New Course
      </Button>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses?.map((course: Course) => (
              <TableRow key={course.id}>
                <TableCell>{course.title}</TableCell>
                <TableCell>{course.description}</TableCell>
                <TableCell>${course.price}</TableCell>
                <TableCell>{course.type}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => openDialog(course)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(course.id!)}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth="lg" fullWidth>
        <DialogTitle>
          {formData.length === 1 && formData[0].id
            ? "Edit Course"
            : "Add New Courses"}
        </DialogTitle>
        <DialogContent>
          {formData.map((course, index) => (
            <div key={index} style={{ marginBottom: "20px" }}>
              <TextField
                label="Title"
                name="title"
                fullWidth
                margin="normal"
                value={course.title}
                onChange={(e: any) => handleChange(e, index)}
              />
              <TextField
                label="Description"
                name="description"
                fullWidth
                margin="normal"
                multiline
                value={course.description}
                onChange={(e: any) => handleChange(e, index)}
              />
              <TextField
                label="Price"
                name="price"
                fullWidth
                margin="normal"
                type="number"
                value={course.price}
                onChange={(e: any) => handleChange(e, index)}
              />
              <TextField
                label="Type"
                name="type"
                fullWidth
                margin="normal"
                select
                SelectProps={{ native: true }}
                value={course.type}
                onChange={(e: any) => handleChange(e, index)}
              >
                <option value="recorded">Recorded</option>
                <option value="live">Live</option>
              </TextField>
              {course.type === "live" && (
                <TextField
                  label="Live Link"
                  name="liveLink"
                  fullWidth
                  margin="normal"
                  value={course.liveLink}
                  onChange={(e: any) => handleChange(e, index)}
                />
              )}
              {formData.length > 1 && (
                <Button
                  color="secondary"
                  onClick={() => removeCourseField(index)}
                >
                  Remove
                </Button>
              )}
              <hr />
            </div>
          ))}
          <Button
            variant="contained"
            color="primary"
            onClick={addNewCourseField}
          >
            Add Another Course
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CoursesPage;
