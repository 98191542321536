import React from "react";
import {
  Typography,
  CircularProgress,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useGetAllAppointmentsQuery } from "../Redux/Api/consultingServiceApi";

const AppointmentsList: React.FC = () => {
  const { data: appointments, isLoading, error } = useGetAllAppointmentsQuery();

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", marginTop: 4 }}>
        <CircularProgress />
        <Typography>Loading appointments...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ marginTop: 4 }}>
        Failed to fetch appointments. Please try again later.
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        All Appointments
      </Typography>
      {appointments && appointments.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>User Email</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Consultant</TableCell>
                <TableCell>Service</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments.map((appointment) => (
                <TableRow key={appointment.id}>
                  <TableCell>{appointment.id}</TableCell>
                  <TableCell>{appointment.userName}</TableCell>
                  <TableCell>{appointment.userEmail}</TableCell>
                  <TableCell>{appointment.date}</TableCell>
                  <TableCell>{appointment.time}</TableCell>
                  <TableCell>
                    {appointment.consultant
                      ? `${appointment.consultant.name} (${appointment.consultant.specialization})`
                      : "No Consultant Assigned"}
                  </TableCell>
                  <TableCell>
                    {appointment.service
                      ? `${appointment.service.title} - $${appointment.service.price}`
                      : "No Service Assigned"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No appointments found.</Typography>
      )}
    </Box>
  );
};

export default AppointmentsList;
