import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetAllLeadershipQuery,
  useCreateLeadershipMutation,
  useUpdateLeadershipMutation,
  useDeleteLeadershipMutation,
} from "../Redux/Api/consultingServiceApi";

interface Leadership {
  id?: number;
  name: string;
  title: string;
  imageUri: string;
}

const LeadershipPage: React.FC = () => {
  const { data: leadership, isLoading } = useGetAllLeadershipQuery();
  const [createLeadership] = useCreateLeadershipMutation();
  const [updateLeadership] = useUpdateLeadershipMutation();
  const [deleteLeadership] = useDeleteLeadershipMutation();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState<Leadership>({
    name: "",
    title: "",
    imageUri: "",
  });
  const [editMode, setEditMode] = useState(false);

  const openDialog = (data: Leadership | null = null) => {
    if (data) {
      setFormData(data);
      setEditMode(true);
    } else {
      setFormData({
        name: "",
        title: "",
        imageUri: "",
      });
      setEditMode(false);
    }
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleSave = async () => {
    if (editMode && formData.id) {
      await updateLeadership({
        id: formData.id,
        data: {
          name: formData.name,
          title: formData.title,
          imageUri: formData.imageUri,
        },
      });
    } else {
      await createLeadership(formData);
    }
    closeDialog();
  };

  const handleDelete = async (id: number) => {
    if (
      window.confirm("Are you sure you want to delete this leadership member?")
    ) {
      await deleteLeadership(id);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Consulting Leadership
      </Typography>
      <Button variant="contained" color="primary" onClick={() => openDialog()}>
        Add New Member
      </Button>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leadership?.map((member: Leadership) => (
              <TableRow key={member.id}>
                <TableCell>{member.name}</TableCell>
                <TableCell>{member.title}</TableCell>
                <TableCell>
                  <img
                    src={member.imageUri}
                    alt={member.name}
                    width="50"
                    height="50"
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => openDialog(member)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(member.id!)}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {editMode ? "Edit Leadership Member" : "Add New Member"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            fullWidth
            margin="normal"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            label="Title"
            name="title"
            fullWidth
            margin="normal"
            value={formData.title}
            onChange={handleChange}
          />
          <TextField
            label="Image URL"
            name="imageUri"
            fullWidth
            margin="normal"
            value={formData.imageUri}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LeadershipPage;
