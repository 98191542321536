import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetAllServicesQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
} from "../Redux/Api/consultingServiceApi";

interface Service {
  id?: number;
  title: string;
  description: string;
  longDescription?: string;
  price?: string;
  imageUrl?: string;
}

const ServicesPage: React.FC = () => {
  const { data: services, isLoading } = useGetAllServicesQuery();
  const [createService] = useCreateServiceMutation();
  const [updateService] = useUpdateServiceMutation();
  const [deleteService] = useDeleteServiceMutation();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState<Service>({
    title: "",
    description: "",
    longDescription: "",
    price: "",
    imageUrl: "",
  });
  const [isEditMode, setEditMode] = useState(false);

  const openDialog = (service: Service | null = null) => {
    if (service) {
      setFormData({ ...service });
      setEditMode(true);
    } else {
      setFormData({
        title: "",
        description: "",
        longDescription: "",
        price: "",
        imageUrl: "",
      });
      setEditMode(false);
    }
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setFormData({
      title: "",
      description: "",
      longDescription: "",
      price: "",
      imageUrl: "",
    });
    setEditMode(false);
  };

  const handleSave = async () => {
    if (isEditMode && formData.id) {
      await updateService({ id: formData.id, data: formData });
    } else {
      await createService(formData);
    }
    closeDialog();
  };

  const handleDelete = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this service?")) {
      await deleteService(id);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Services
      </Typography>
      <Button variant="contained" color="primary" onClick={() => openDialog()}>
        Add New Service
      </Button>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Long Description</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services?.map((service: Service) => (
              <TableRow key={service.id}>
                <TableCell>{service.title}</TableCell>
                <TableCell>{service.description}</TableCell>
                <TableCell>{service.longDescription}</TableCell>
                <TableCell>${service.price}</TableCell>

                <TableCell>
                  <img
                    src={service.imageUrl}
                    alt={service.title}
                    style={{ width: "50px", height: "50px" }}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => openDialog(service)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(service.id!)}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>
          {isEditMode ? "Edit Service" : "Add New Service"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            name="title"
            fullWidth
            margin="normal"
            value={formData.title}
            onChange={handleChange}
          />
          <TextField
            label="Description"
            name="description"
            fullWidth
            margin="normal"
            multiline
            rows={2}
            value={formData.description}
            onChange={handleChange}
          />
          <TextField
            label="Long Description"
            name="longDescription"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={formData.longDescription}
            onChange={handleChange}
          />
          <TextField
            label="Price"
            name="price"
            fullWidth
            margin="normal"
            type="number"
            value={formData.price}
            onChange={handleChange}
          />
          <TextField
            label="Image URL"
            name="imageUrl"
            fullWidth
            margin="normal"
            value={formData.imageUrl}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ServicesPage;
