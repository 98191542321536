import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Availability, Consultant } from "../types";
import { getToken } from "../../util/AuthUtils";

export const consultingServiceApi = createApi({
  reducerPath: "consultingServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "Consultant",
    "Availability",
    "AboutConsulting",
    "Course",
    "Client",
    "Service",
    "Industry",
    "Leadership",
    "Appointment",
  ],
  endpoints: (builder) => ({
    getConsultants: builder.query<Consultant[], void>({
      query: () => "consultants",
      transformResponse: (response: any) => response.result || [],
      providesTags: ["Consultant"],
    }),
    addConsultant: builder.mutation<void, Partial<Consultant>>({
      query: (consultant) => ({
        url: "consultants",
        method: "POST",
        body: consultant,
      }),
      invalidatesTags: ["Consultant"],
    }),
    updateConsultant: builder.mutation<
      void,
      { id: number; data: Partial<Consultant> }
    >({
      query: ({ id, data }) => ({
        url: `consultants/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Consultant"],
    }),
    deleteConsultant: builder.mutation<void, number>({
      query: (id) => ({
        url: `consultants/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Consultant"],
    }),

    getAvailabilityByConsultantId: builder.query<Availability[], number>({
      query: (consultantId) => `availability/${consultantId}`,
      transformResponse: (response: any) => response.result || [],
      providesTags: ["Availability"],
    }),
    setAvailability: builder.mutation<
      void,
      { consultantId: number; availabilityData: Availability[] }
    >({
      query: ({ consultantId, availabilityData }) => ({
        url: `availability/${consultantId}`,
        method: "POST",
        body: availabilityData,
      }),
      invalidatesTags: ["Availability"],
    }),

    getAboutConsulting: builder.query<any, void>({
      query: () => "about-consulting",
      transformResponse: (response: any) => response.result || null,
      providesTags: ["AboutConsulting"],
    }),
    updateAboutConsulting: builder.mutation<void, Partial<any>>({
      query: (aboutConsultingData) => ({
        url: "about-consulting",
        method: "PUT",
        body: aboutConsultingData,
      }),
      invalidatesTags: ["AboutConsulting"],
    }),

    getAllCourses: builder.query<any[], void>({
      query: () => "courses",
      transformResponse: (response: any) => response.result || [],
      providesTags: ["Course"],
    }),
    addCourses: builder.mutation<void, any[]>({
      query: (courses) => ({
        url: "courses",
        method: "POST",
        body: courses,
      }),
      invalidatesTags: ["Course"],
    }),
    updateCourse: builder.mutation<void, { id: number; data: Partial<any> }>({
      query: ({ id, data }) => ({
        url: `courses/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Course"],
    }),
    deleteCourse: builder.mutation<void, number>({
      query: (id) => ({
        url: `courses/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Course"],
    }),

    getAllClients: builder.query<any[], void>({
      query: () => "clients",
      transformResponse: (response: any) => response.result || [],
      providesTags: ["Client"],
    }),
    createClient: builder.mutation<void, { name: string; logoUri: string }>({
      query: (client) => ({
        url: "clients",
        method: "POST",
        body: client,
      }),
      invalidatesTags: ["Client"],
    }),
    updateClient: builder.mutation<
      void,
      { id: number; name?: string; logoUri?: string }
    >({
      query: ({ id, ...data }) => ({
        url: `clients/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Client"],
    }),
    deleteClient: builder.mutation<void, number>({
      query: (id) => ({
        url: `clients/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Client"],
    }),

    getAllServices: builder.query<any[], void>({
      query: () => "services",
      transformResponse: (response: any) => response.result || [],
      providesTags: ["Service"],
    }),
    createService: builder.mutation<void, Partial<any>>({
      query: (service) => ({
        url: "services",
        method: "POST",
        body: service,
      }),
      invalidatesTags: ["Service"],
    }),
    updateService: builder.mutation<void, { id: number; data: Partial<any> }>({
      query: ({ id, data }) => ({
        url: `services/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Service"],
    }),
    deleteService: builder.mutation<void, number>({
      query: (id) => ({
        url: `services/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Service"],
    }),
    getAllIndustries: builder.query<any[], void>({
      query: () => "industries",
      transformResponse: (response: any) => response.result || [],
      providesTags: ["Industry"],
    }),
    createIndustry: builder.mutation<
      void,
      { title: string; description: string; image: string }
    >({
      query: (industry) => ({
        url: "industries",
        method: "POST",
        body: industry,
      }),
      invalidatesTags: ["Industry"],
    }),
    updateIndustry: builder.mutation<void, { id: number; data: Partial<any> }>({
      query: ({ id, data }) => ({
        url: `industries/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Industry"],
    }),
    deleteIndustry: builder.mutation<void, number>({
      query: (id) => ({
        url: `industries/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Industry"],
    }),

    getAllLeadership: builder.query<any[], void>({
      query: () => "leadership",
      transformResponse: (response: any) => response.result || [],
      providesTags: ["Leadership"],
    }),
    createLeadership: builder.mutation<
      void,
      { name: string; title: string; imageUri: string }
    >({
      query: (leadership) => ({
        url: "leadership",
        method: "POST",
        body: leadership,
      }),
      invalidatesTags: ["Leadership"],
    }),
    updateLeadership: builder.mutation<
      void,
      { id: number; data: { name: string; title: string; imageUri: string } }
    >({
      query: ({ id, data }) => ({
        url: `leadership/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Leadership"],
    }),
    deleteLeadership: builder.mutation<void, number>({
      query: (id) => ({
        url: `leadership/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Leadership"],
    }),
    getAllAppointments: builder.query<any[], void>({
      query: () => "appointments",
      transformResponse: (response: any) => response.result || [],
      providesTags: ["Appointment"],
    }),
  }),
});

export const {
  useGetConsultantsQuery,
  useAddConsultantMutation,
  useUpdateConsultantMutation,
  useDeleteConsultantMutation,

  useGetAvailabilityByConsultantIdQuery,
  useSetAvailabilityMutation,

  useGetAboutConsultingQuery,
  useUpdateAboutConsultingMutation,

  useGetAllCoursesQuery,
  useAddCoursesMutation,
  useUpdateCourseMutation,
  useDeleteCourseMutation,

  useGetAllClientsQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,

  useGetAllServicesQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,

  useGetAllIndustriesQuery,
  useCreateIndustryMutation,
  useUpdateIndustryMutation,
  useDeleteIndustryMutation,

  useGetAllLeadershipQuery,
  useCreateLeadershipMutation,
  useUpdateLeadershipMutation,
  useDeleteLeadershipMutation,

  useGetAllAppointmentsQuery,
} = consultingServiceApi;
