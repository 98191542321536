import React, { useEffect, useState } from "react";
import { TextField, Button, Typography, Box, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  useGetAboutConsultingQuery,
  useUpdateAboutConsultingMutation,
} from "../Redux/Api/consultingServiceApi";

const AboutConsultingForm: React.FC = () => {
  const { data: aboutData, isLoading } = useGetAboutConsultingQuery();
  const [updateAboutConsulting] = useUpdateAboutConsultingMutation();

  const [formData, setFormData] = useState({
    title: "",
    shortDescription: "",
    description: "",
    teamMembers: [{ name: "", designation: "", pictureUrl: "" }],
    feedback: [{ title: "", description: "", imageUrl: "" }],
  });

  useEffect(() => {
    if (aboutData) {
      setFormData(aboutData);
    }
  }, [aboutData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTeamMemberChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const updatedMembers = [...formData.teamMembers];
    updatedMembers[index] = { ...updatedMembers[index], [field]: value }; // Shallow copy to avoid mutation
    setFormData({ ...formData, teamMembers: updatedMembers });
  };

  const handleFeedbackChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const updatedFeedback = [...formData.feedback];
    updatedFeedback[index] = { ...updatedFeedback[index], [field]: value }; // Shallow copy to avoid mutation
    setFormData({ ...formData, feedback: updatedFeedback });
  };

  const handleAddTeamMember = () => {
    setFormData({
      ...formData,
      teamMembers: [
        ...formData.teamMembers,
        { name: "", designation: "", pictureUrl: "" },
      ],
    });
  };

  const handleRemoveTeamMember = (index: number) => {
    const updatedMembers = formData.teamMembers.filter((_, i) => i !== index);
    setFormData({ ...formData, teamMembers: updatedMembers });
  };

  const handleAddFeedback = () => {
    setFormData({
      ...formData,
      feedback: [
        ...formData.feedback,
        { title: "", description: "", imageUrl: "" },
      ],
    });
  };

  const handleRemoveFeedback = (index: number) => {
    const updatedFeedback = formData.feedback.filter((_, i) => i !== index);
    setFormData({ ...formData, feedback: updatedFeedback });
  };

  const handleSubmit = async () => {
    await updateAboutConsulting(formData);
    alert("About Consulting updated successfully!");
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Edit About Consulting
      </Typography>
      <TextField
        label="Title"
        name="title"
        fullWidth
        margin="normal"
        value={formData.title}
        onChange={handleChange}
      />
      <TextField
        label="Short Description"
        name="shortDescription"
        fullWidth
        margin="normal"
        multiline
        value={formData.shortDescription}
        onChange={handleChange}
      />
      <TextField
        label="Description"
        name="description"
        fullWidth
        margin="normal"
        multiline
        rows={4}
        value={formData.description}
        onChange={handleChange}
      />
      <Typography variant="h6" gutterBottom>
        Team Members
      </Typography>
      {formData.teamMembers.map((member, index) => (
        <Box
          key={index}
          sx={{
            marginBottom: "20px",
            borderBottom: "1px solid #ddd",
            paddingBottom: "10px",
          }}
        >
          <TextField
            label="Name"
            name="name"
            fullWidth
            margin="normal"
            value={member.name}
            onChange={(e) =>
              handleTeamMemberChange(index, "name", e.target.value)
            }
          />
          <TextField
            label="Designation"
            name="designation"
            fullWidth
            margin="normal"
            value={member.designation}
            onChange={(e) =>
              handleTeamMemberChange(index, "designation", e.target.value)
            }
          />
          <TextField
            label="Picture URL"
            name="pictureUrl"
            fullWidth
            margin="normal"
            value={member.pictureUrl}
            onChange={(e) =>
              handleTeamMemberChange(index, "pictureUrl", e.target.value)
            }
          />
          <IconButton
            color="secondary"
            onClick={() => handleRemoveTeamMember(index)}
          >
            <RemoveCircleIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="outlined"
        startIcon={<AddCircleIcon />}
        onClick={handleAddTeamMember}
      >
        Add Team Member
      </Button>

      <Typography variant="h6" gutterBottom sx={{ marginTop: "20px" }}>
        Feedback
      </Typography>
      {formData.feedback.map((item, index) => (
        <Box
          key={index}
          sx={{
            marginBottom: "20px",
            borderBottom: "1px solid #ddd",
            paddingBottom: "10px",
          }}
        >
          <TextField
            label="Title"
            name="title"
            fullWidth
            margin="normal"
            value={item.title}
            onChange={(e) =>
              handleFeedbackChange(index, "title", e.target.value)
            }
          />
          <TextField
            label="Description"
            name="description"
            fullWidth
            margin="normal"
            value={item.description}
            onChange={(e) =>
              handleFeedbackChange(index, "description", e.target.value)
            }
          />
          <TextField
            label="Image URL"
            name="imageUrl"
            fullWidth
            margin="normal"
            value={item.imageUrl}
            onChange={(e) =>
              handleFeedbackChange(index, "imageUrl", e.target.value)
            }
          />
          <IconButton
            color="secondary"
            onClick={() => handleRemoveFeedback(index)}
          >
            <RemoveCircleIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="outlined"
        startIcon={<AddCircleIcon />}
        onClick={handleAddFeedback}
      >
        Add Feedback
      </Button>

      <Box sx={{ marginTop: "20px" }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default AboutConsultingForm;
